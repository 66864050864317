import React, {
  createContext, useCallback,
} from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import _ from 'lodash';
//
import useSources from './useSources';
import useChannels from './useChannels';
import useMessages from './useMessages';
import useWebsocket from './useWebsocket';

export const HubContext = createContext( {} );

const HubContextProvider = ( {
  toggleErrorAlert,
  propertyManager,
  participantId,
  channelType,
  customChannelsFilters = {},
  children,
} ) => {
  //  PARTICIPANT ID
  // const [participantId, setParticipantId] = useState( userId );

  //  CHANNELS
  const [
    selectedChannelId,
    setSelectedChannelId,
    selectedChannel,
    loadingSelectedChannel,
    channels,
    filterChannels,
    setFilterChannels,
    loadingChannels,
    channelAlert,
    setChannelAlert,
  ] = useChannels( toggleErrorAlert, channelType,
    propertyManager.id, customChannelsFilters /* , setParticipantId */ );

  //  SOURCES
  const [
    sources,
    loadingSources,
    selectedSourceId,
    setSelectedSourceId,
  ] = useSources( /* participantId, toggleErrorAlert, */ selectedChannel );

  //  MESSAGES
  const [
    messages,
    resetMessages,
    loadingMessages,
    filterMessages,
    setFilterMessages,
    enabledSubmitMessage,
    submitMessage,
    loadingMessage,
    addNewMessages,
    updateMessageView,
  ] = useMessages(
    participantId,
    selectedChannelId,
    // selectedChannel,
    selectedSourceId,
    toggleErrorAlert,
  );

  //  SELECT CHANNEL
  const selectChannel = useCallback(
    ( channelId ) => {
      resetMessages();
      setSelectedSourceId( null );
      setSelectedChannelId( channelId );
    },
    [resetMessages, setSelectedSourceId, setSelectedChannelId],
  );

  //  SELECT SOURCE
  const selectSource = useCallback(
    ( sourceId ) => {
      resetMessages();
      setSelectedSourceId( sourceId );
    },
    [resetMessages, setSelectedSourceId],
  );

  // WEB SOCKET
  useWebsocket( propertyManager.id, selectedChannelId, setChannelAlert, addNewMessages );

  return (
    <HubContext.Provider
      value={{
        // PARTICIPANT
        participantId,

        // SOURCES
        sources,
        loadingSources,
        selectedSourceId,
        setSelectedSourceId,

        // SELECTED CHANNEL
        selectedChannelId,
        selectedChannel,
        loadingSelectedChannel,

        // CHANNELS
        channels,
        loadingChannels,
        filterChannels,
        setFilterChannels,
        channelAlert,
        setChannelAlert,

        // MESSAGES
        messages,
        loadingMessages,
        filterMessages,
        setFilterMessages,
        updateMessageView,

        // MESSAGE SENDER
        submitMessage,
        loadingMessage,
        enabledSubmitMessage,

        // SELECT CHANNELS
        selectChannel,

        // SELECT SOURCE
        selectSource,
      }}
    >
      {children}
    </HubContext.Provider>
  );
};

const mapStateToProps = ( { user } ) => ( {
  participantId: user?.user?.id || '',
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = {
  // toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  // toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( HubContextProvider );
