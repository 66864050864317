import React, { useContext, useMemo } from 'react';
import { HubContext } from 'components/Hub/context';
import moment from 'moment';
import classnames from 'classnames';
import TimeAgo from 'react-timeago';
import { timeagoFormatter } from 'utils';
import { getInitials } from 'components/Hub/utils';

const ChannelItem = ( { channelData } ) => {
  const {
    selectedChannelId, selectChannel, channelAlert, setChannelAlert,
  } = useContext( HubContext );

  const {
    id, name, initials, photo, date, enabled, isActive,
  } = useMemo( () => {
    const {
      // eslint-disable-next-line no-shadow
      id, name, photo, updatedAt, enabled,
    } = channelData;

    return {
      id,
      name,
      initials: getInitials( name ),
      photo,
      date: updatedAt ? moment.utc( updatedAt ).local() : null,
      enabled,
      isActive: selectedChannelId === id,
    };
  }, [channelData, selectedChannelId] );

  return (
    <div
      className={classnames( 'hub-channel-list-item', {
        'is-active': isActive,
        'not-readed': channelAlert[id],
      } )}
      onClick={() => {
        if ( !enabled ) {
          return;
        }
        if ( channelAlert[id] ) {
          setChannelAlert( ( oldChannelAlert ) => {
            const channelAlertCopy = { ...oldChannelAlert };
            channelAlertCopy[id] = 0;
            return channelAlertCopy;
          } );
        }
        selectChannel( id );
      }}
    >
      <div>
        <div className="avatar rounded-circle">
          {photo ? (
            <img
              alt={initials}
              src={photo}
              className="avatar rounded-circle"
            />
          ) : initials}
        </div>
      </div>
      <div>

        <p className="text-darker text-sm font-weight-500 m-0 text-uppercase">
          {name}
          {channelAlert[id] ? <span className="hub-channel-list-alert">{channelAlert[id] || '0'}</span> : null}
        </p>
      </div>

      {date
        ? (
          <div className="text-darker text-xsm ml-auto">
            <TimeAgo
              date={date}
              formatter={timeagoFormatter}
            />
          </div>
        ) : null}

    </div>
  );
};

export default ChannelItem;
