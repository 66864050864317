import {
  useEffect,
} from 'react';

const urlSocket = process.env.REACT_APP_HUB_WEBSOCKET_URL;

const useWebsocket = ( propertyManagerId, selectedChannelId, setChannelAlert, addNewMessages ) => {
  useEffect( () => {
    let ws = new WebSocket( `${urlSocket}?topic=${propertyManagerId}` );

    // ws.onopen = () => {    };

    ws.onmessage = ( event ) => {
      const { data } = JSON.parse( event.data );
      if ( data ) {
        const channelId = data?.channel?.id || 'none';
        const message = data?.message || null;

        if ( message ) {
          if ( channelId === selectedChannelId ) {
            addNewMessages( [message] );
          } else {
            setChannelAlert( ( oldChannelAlert ) => {
              const channelAlertCopy = { ...oldChannelAlert };
              if ( !channelAlertCopy[channelId] ) {
                channelAlertCopy[channelId] = 0;
              }
              channelAlertCopy[channelId] += 1;
              return channelAlertCopy;
            } );
          }
        }
      }
    };

    // ws.onclose = () => { };

    return () => {
      ws.close();
      ws = null;
    };
  }, [propertyManagerId, selectedChannelId, setChannelAlert, addNewMessages] );

  return {};
};

export default useWebsocket;
