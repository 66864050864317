import {
  useMemo,
  useState,
} from 'react';
// import { useFetch } from 'hooks';
// import SourceService from 'api/Hub/Source';

const loadingSources = false;

const useSources = ( /* participantId, toggleErrorAlert, */selectedChannel ) => {
  const [selectedSourceId, setSelectedSourceId] = useState( null );

  /*
  const [sources, loadingSources] = useFetch( {
    promise: () => SourceService.getSources( {
      page: 1,
      limit: 999,
      'filters[participantId]': participantId,
    } ),
    initialState: {
      data: [],
      total: 0,
    },
    toggleErrorAlert,
    deps: [participantId],
    reInit: true,
    // callback: ( pId, resp ) => {
    //   setSelectedSourceId( resp?.data[0]?.id );
    // },
  } );
  */

  const sources = useMemo( () => {
    if ( !selectedChannel ) { return []; }
    return selectedChannel?.sources || [];
  }, [selectedChannel] );

  return [sources, loadingSources, selectedSourceId, setSelectedSourceId];
};
export default useSources;
