import React from 'react';
import Hub from 'components/Hub';

const ContactCommunicationsView = ( { contactId } ) => (
  <Hub
    channelType="crm"
    className="p-0"
    customChannelsFilters={{
      'filters[participantId]': contactId,
    }}
  />
);

export default ContactCommunicationsView;
