import api from 'config/Api/ApiHub';
import { formatFormDataBody } from 'utils';

const baseEndpoint = '/messages';

export default {
  getMessages( params ) {
    return api.get( baseEndpoint, params );
  },
  getMessage( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },
  createMessage( data, files ) {
    const fdata = formatFormDataBody( data );

    if ( files ) {
      fdata.append( 'files', files );
    }
    return api.post( baseEndpoint, fdata );
  },
  updateMessage( id, data ) {
    return api.put(
      `${baseEndpoint}/${id}`,
      data,
    );
  },
  deleteMessage( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },
};
