import React, {
  useCallback,
  useContext, useEffect, useRef, useState,
} from 'react';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import Lightbox from 'react-image-lightbox';
import { HubContext } from '../context';
import MessageItem from './message';
import MessageSender from './message-sender';
import MessageHeader from './message-header';
import { isSameDay } from '../utils';
import HubLoader from '../hub-loader';

const MessageList = () => {
  const {
    messages, loadingMessages,
    loadingSelectedChannel, filterMessages,
    setFilterMessages, selectedChannelId,
    updateMessageView,
  } = useContext( HubContext );

  const listRef = useRef( null );

  useEffect( () => {
    const timer = setTimeout( () => {
      listRef.current.scrollTop = 9999999;
    }, 200 );
    return () => clearTimeout( timer );
  }, [updateMessageView] );

  const [showList, setShowList] = useState( true );

  useEffect( () => {
    setShowList( false );
    const timer = setTimeout( () => {
      setShowList( true );
    }, 200 );
    return () => clearTimeout( timer );
  }, [selectedChannelId] );

  // Lightbox
  const [showImageModal, setShowImageModal] = useState( false );
  const imageToShow = useRef( null );

  const toggleImageModal = useCallback( ( src ) => {
    imageToShow.current = src;
    setShowImageModal( ( v ) => !v );
  }, [] );

  return (
    <>
      <div className="hub-message-list">
        <MessageHeader />
        <div className="hub-message-list-body" ref={listRef}>
          <HubLoader loading={loadingMessages || loadingSelectedChannel} key="loading" />
          {showList ? (
            <InfiniteScroll
              pageStart={1}
              initialLoad={false}
              loadMore={( page ) => {
                setFilterMessages( { page } );
              }}
              hasMore={messages.totalPages
            > filterMessages.page}
              useWindow={false}
              isReverse
            >
              {messages.data.map( ( messageData, index ) => {
                const messageDataPrevious = index > 0 ? messages.data[index - 1] : null;

                return (
                  <div key={messageData.id}>
                    {!isSameDay( messageData, messageDataPrevious ) ? (
                      <div className="hub-message-list-body-date">{moment.utc( new Date( messageData.createdAt ) ).local().format( 'MMM DD, YYYY' )}</div>
                    ) : null}
                    <MessageItem
                      messageData={messageData}
                      onToggleImageModal={toggleImageModal}
                    />
                  </div>
                );
              } )}
            </InfiniteScroll>
          ) : null}
        </div>
        <footer className="hub-message-list-footer">
          <MessageSender />
        </footer>
      </div>

      {showImageModal && (
        <Lightbox
          mainSrc={imageToShow.current}
          nextSrc={null}
          prevSrc={null}
          onCloseRequest={toggleImageModal}
        />
      )}
    </>

  );
};

export default MessageList;
