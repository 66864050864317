import React, { useContext, useMemo } from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import { useFetch } from 'hooks';
import classnames from 'classnames';
import BookingService from 'api/BookingItem';
import TaskService from 'api/Task';
import BookedServiceService from 'api/BookedService';
import { HubContext } from '../context';
import BookingView from './booking';

const HubProps = ( { toggleErrorAlert } ) => {
  const {
    selectedChannel, loadingSelectedChannel,
  } = useContext( HubContext );

  const bookingId = useMemo( () => {
    if ( !selectedChannel || loadingSelectedChannel ) {
      return null;
    }

    return selectedChannel?.metadata?.bookingId ?? null;
  }, [selectedChannel, loadingSelectedChannel] );

  const [dataBooking, dataBookingLoading] = useFetch( {
    initialState: {},
    promise: () => BookingService.getBookingItem( bookingId ),
    toggleErrorAlert,
    conditional: !!bookingId,
    deps: [bookingId],
    reInit: true,
  } );
  const [dataTasksBooking, dataTasksBookingLoading] = useFetch( {
    initialState: [],
    promise: () => TaskService.getTasks( { bookedAccommodation: bookingId } ),
    toggleErrorAlert,
    conditional: !!bookingId,
    deps: [bookingId],
    format: ( dataToFormat ) => ( dataToFormat?.data || [] ),
  } );

  const [dataServicesBooking, dataServicesBookingLoading] = useFetch( {
    initialState: [],
    promise: () => BookedServiceService.getBookedServices( { booking: bookingId } ),
    toggleErrorAlert,
    conditional: !!bookingId,
    deps: [bookingId],
    format: ( dataToFormat ) => ( dataToFormat?.data || [] ),
  } );

  const isloading = dataBookingLoading
  || loadingSelectedChannel
  || dataTasksBookingLoading
  || dataServicesBookingLoading;

  return (
    <div
      className={classnames( 'hub-props', {
        'app-component-loading': isloading,
      } )}
    >
      {isloading || Object.keys( dataBooking ).length === 0 ? null : (
        <BookingView data={{
          ...dataBooking,
          tasks: dataTasksBooking,
          services: dataServicesBooking,
        }}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = ( {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( HubProps );
