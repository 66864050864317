import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import CustomerAppEditView from 'views/CustomerApp';
import SettingsActions from 'store/reducers/Settings';
import CustomerAppService from 'api/Settings/CustomerApp';
import { useFetch } from 'hooks';
import { formatDataToSend, handlePromise } from 'utils';

const formatDataTranslation = ( dataToFormat, field ) => _.keys( dataToFormat ).reduce(
  ( acu, lng ) => ( {
    ...acu,
    [lng]: { [field]: dataToFormat[lng] },
  } ),
  {},
);

const formatTranslation = ( _data, field ) => {
  const translationFieldData = _.filter( _data, ( o ) => o.field === field );
  if ( translationFieldData[0] ) {
    return _.keys( translationFieldData[0].trans ).map( ( k ) => ( {
      field: k,
      trans: translationFieldData[0].trans[k],
    } ) );
  }
  return [];
};

const translationModules = [
  'services',
  'events',
  'communityRules',
  'appliancesTutorials',
];

const motives = [
  'culture',
  'leisure',
  'business',
  'commercial',
  'professional',
  'artisan',
  'assistance',
  'teaching',
];

const formatTranlationWitoutModules = ( allTranslationData ) => allTranslationData.reduce(
  ( acc, { lang, data: translationsData } ) => {
    const translationWithoutModules = _.filter(
      translationsData,
      ( o ) => !translationModules.includes( o.field ),
    );
    return ( [
      ...acc,
      {
        lang,
        data: translationWithoutModules,
      },
    ] );
  }, [],
);

const CustomerAppSettingsEdit = ( {
  propertyManager, toggleLoading, toggleInfoAlert, toggleErrorAlert,
} ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => CustomerAppService.getCustomerApp( { propertyManager: propertyManager.id } ),
    toggleErrorAlert,
    conditional: !!propertyManager.id,
    deps: [propertyManager.id, dataSubmitted],
  } );

  const customerAppId = _.get( data, 'id', false );
  const [dataTranslation, dataTranslationLoading] = useFetch( {
    initialState: {},
    promise: () => CustomerAppService.getTranslation( customerAppId ),
    toggleErrorAlert,
    conditional: !!customerAppId,
    deps: [customerAppId, dataSubmitted],
  } );

  const eventsId = _.get( data, 'events.id', false );
  const [
    dataTranslationEventModule,
    dataTranslationEventModuleLoading,
  ] = useFetch( {
    initialState: {},
    promise: () => CustomerAppService.getTranslationEventModule( eventsId ),
    toggleErrorAlert,
    conditional: !!eventsId,
    format: ( dataToFormat ) => formatDataTranslation( dataToFormat, 'events' ),
    deps: [eventsId, dataSubmitted],
  } );

  const serviceId = _.get( data, 'services.id', false );
  const [
    dataTranslationService,
    dataTranslationServiceLoading,
  ] = useFetch( {
    initialState: {},
    promise: () => CustomerAppService.getTranslationBasicModule( serviceId ),
    toggleErrorAlert,
    conditional: !!serviceId,
    format: ( dataToFormat ) => formatDataTranslation( dataToFormat, 'services' ),
    deps: [serviceId, dataSubmitted],
  } );

  const communityRulesId = _.get( data, 'communityRules.id', false );
  const [
    dataTranslationCommunityRules,
    dataTranslationCommunityRulesLoading,
  ] = useFetch( {
    initialState: {},
    promise: () => CustomerAppService.getTranslationBasicModule( communityRulesId ),
    toggleErrorAlert,
    conditional: !!communityRulesId,
    format: ( dataToFormat ) => formatDataTranslation( dataToFormat, 'communityRules' ),
    deps: [communityRulesId, dataSubmitted],
  } );

  const appliancesTutorialsId = _.get( data, 'appliancesTutorials.id', false );
  const [
    dataTranslationAppliancesTutorials,
    dataTranslationAppliancesTutorialsLoading,
  ] = useFetch( {
    initialState: {},
    promise: () => CustomerAppService.getTranslationBasicModule( appliancesTutorialsId ),
    toggleErrorAlert,
    conditional: !!appliancesTutorialsId,
    format: ( dataToFormat ) => formatDataTranslation( dataToFormat, 'appliancesTutorials' ),
    deps: [appliancesTutorialsId, dataSubmitted],
  } );

  const isLoadingData = () => {
    if ( dataLoading === undefined ) return undefined;
    if ( dataTranslationLoading === undefined ) return undefined;
    if ( dataTranslationEventModuleLoading === undefined ) return undefined;
    if ( dataTranslationServiceLoading === undefined ) return undefined;
    if ( dataTranslationCommunityRulesLoading === undefined ) return undefined;
    if ( dataTranslationAppliancesTutorialsLoading === undefined ) return undefined;
    if ( dataLoading ) return true;
    if ( dataTranslationLoading ) return true;
    if ( dataTranslationEventModuleLoading ) return true;
    if ( dataTranslationServiceLoading ) return true;
    if ( dataTranslationCommunityRulesLoading ) return true;
    if ( dataTranslationAppliancesTutorialsLoading ) return true;
    return false;
  };

  const formatData = () => {
    let translations = _.merge(
      dataTranslation,
      dataTranslationEventModule,
      dataTranslationService,
      dataTranslationCommunityRules,
      dataTranslationAppliancesTutorials,
    );
    translations = _.isArray( translations ) ? {} : translations;
    const enabledTravelMotives = data.motives && data.motives.length;
    const _data = {
      ...data,
      enabledTravelMotives,
      translations,
    };
    return _data;
  };

  const submitForm = useCallback( async ( formData, form ) => {
    toggleLoading( true );
    const translation = true;
    const dataToSend = formatDataToSend( formData, form, translation );
    dataToSend.enabled = dataToSend.enabled ? 'true' : 'false';
    dataToSend.contracts = dataToSend.contracts ? 'true' : 'false';
    dataToSend.payments = dataToSend.payments ? 'true' : 'false';
    dataToSend.smartLockerPin = dataToSend.smartLockerPin ? 'true' : 'false';
    dataToSend.reportIssues = dataToSend.reportIssues ? 'true' : 'false';
    dataToSend.travelReportRequired = dataToSend.travelReportRequired ? 'true' : 'false';
    dataToSend.precheckinRequired = dataToSend.precheckinRequired ? 'true' : 'false';
    dataToSend.services.enabled = dataToSend.services.enabled ? 'true' : 'false';
    dataToSend.events.enabled = dataToSend.events.enabled ? 'true' : 'false';
    dataToSend.events.customerPublish = dataToSend.events.customerPublish ? 'true' : 'false';
    dataToSend.appliancesTutorials.enabled = dataToSend.appliancesTutorials.enabled ? 'true' : 'false';
    dataToSend.communityRules.enabled = dataToSend.communityRules.enabled ? 'true' : 'false';
    dataToSend.wtRequestTravelInfo = dataToSend.wtRequestTravelInfo ? 'true' : 'false';
    dataToSend.wtArrivalTime = dataToSend.wtArrivalTime ? 'true' : 'false';
    dataToSend.wtArrivalTransport = dataToSend.wtArrivalTransport ? 'true' : 'false';
    dataToSend.showDefaultAccommodation = dataToSend.showDefaultAccommodation ? 'true' : 'false';
    dataToSend.wtDepartureTime = dataToSend.wtDepartureTime ? 'true' : 'false';
    dataToSend.motives = dataToSend.enabledTravelMotives ? motives : [];

    if ( dataToSend.weekFrom && dataToSend.weekFrom.format ) {
      dataToSend.weekFrom = dataToSend.weekFrom.format( 'HH:mm' );
    }

    if ( dataToSend.weekTill && dataToSend.weekTill.format ) {
      dataToSend.weekTill = dataToSend.weekTill.format( 'HH:mm' );
    }

    if ( dataToSend.weekendFrom && dataToSend.weekendFrom.format ) {
      dataToSend.weekendFrom = dataToSend.weekendFrom.format( 'HH:mm' );
    }

    if ( dataToSend.weekendTill && dataToSend.weekendTill.format ) {
      dataToSend.weekendTill = dataToSend.weekendTill.format( 'HH:mm' );
    }

    const allTranslationData = _.get( dataToSend, 'translations', [] );

    if ( allTranslationData.length > 0 ) {
      dataToSend.translations = formatTranlationWitoutModules( allTranslationData );
      allTranslationData.forEach( ( { lang, data: translationsData } ) => {
        translationModules.forEach( ( module ) => {
          dataToSend[module].translations = [
            ..._.get( dataToSend, `${module}.translations`, [] ),
            {
              lang,
              data: formatTranslation( translationsData, module ),
            },
          ];
        } );
      } );
    }

    delete dataToSend.services.banner;
    delete dataToSend.appliancesTutorials.banner;
    delete dataToSend.logo;
    delete dataToSend.logoBig;
    delete dataToSend.appIcon;
    delete dataToSend.defaultAccommodationPicture;
    delete dataToSend.bannerAppInvite;
    delete dataToSend.enabledTravelMotives;

    const [errors, response] = await handlePromise(
      CustomerAppService.updateCustomerApp( dataToSend ),
    );
    if ( !response.ok ) {
      toggleLoading( false );
      return errors;
    }

    if ( _.get( formData.services, 'banner.file' ) ) {
      const serviceBannerToSend = {
        image: formData.services.banner.file,
        propertyName: 'services',
        propertyManager: propertyManager.id,
      };
      const [imageErrors, imageResponse] = await handlePromise(
        CustomerAppService.updateCustomerAppImage( serviceBannerToSend ),
      );
      if ( !imageResponse.ok ) {
        toggleLoading( false );
        return imageErrors;
      }
    }

    if ( _.get( formData.appliancesTutorials, 'banner.file' ) ) {
      const appliancesBannerToSend = {
        image: formData.appliancesTutorials.banner.file,
        propertyName: 'appliancesTutorials',
        propertyManager: propertyManager.id,
      };
      const [imageErrors, imageResponse] = await handlePromise(
        CustomerAppService.updateCustomerAppImage( appliancesBannerToSend ),
      );
      if ( !imageResponse.ok ) {
        toggleLoading( false );
        return imageErrors;
      }
    }

    if ( _.get( formData, 'logo.file' ) ) {
      const logoToSend = {
        image: formData.logo.file,
        propertyName: 'logo',
        propertyManager: propertyManager.id,
      };
      const [imageErrors, imageResponse] = await handlePromise(
        CustomerAppService.updateCustomerAppImage( logoToSend ),
      );
      if ( !imageResponse.ok ) {
        toggleLoading( false );
        return imageErrors;
      }
    }

    if ( _.get( formData, 'appIcon.file' ) ) {
      const appIconToSend = {
        image: formData.appIcon.file,
        propertyName: 'appIcon',
        propertyManager: propertyManager.id,
      };
      const [imageErrors, imageResponse] = await handlePromise(
        CustomerAppService.updateCustomerAppImage( appIconToSend ),
      );
      if ( !imageResponse.ok ) {
        toggleLoading( false );
        return imageErrors;
      }
    }

    if ( _.get( formData, 'bannerAppInvite.file' ) ) {
      const bannerAppInviteToSend = {
        image: formData.bannerAppInvite.file,
        propertyName: 'bannerAppInvite',
        propertyManager: propertyManager.id,
      };
      const [imageErrors, imageResponse] = await handlePromise(
        CustomerAppService.updateCustomerAppImage( bannerAppInviteToSend ),
      );
      if ( !imageResponse.ok ) {
        toggleLoading( false );
        return imageErrors;
      }
    }

    if ( _.get( formData, 'logoBig.file' ) ) {
      const logoBigToSend = {
        image: formData.logoBig.file,
        propertyName: 'logoBig',
        propertyManager: propertyManager.id,
      };
      const [imageErrors, imageResponse] = await handlePromise(
        CustomerAppService.updateCustomerAppImage( logoBigToSend ),
      );
      if ( !imageResponse.ok ) {
        toggleLoading( false );
        return imageErrors;
      }
    }

    if ( _.get( formData, 'defaultAccommodationPicture.file' ) ) {
      const defaultAccommodationPictureToSend = {
        image: formData.defaultAccommodationPicture.file,
        propertyName: 'defaultAccommodationPicture',
        propertyManager: propertyManager.id,
      };
      const [imageErrors, imageResponse] = await handlePromise(
        CustomerAppService.updateCustomerAppImage( defaultAccommodationPictureToSend ),
      );
      if ( !imageResponse.ok ) {
        toggleLoading( false );
        return imageErrors;
      }
    }

    setDataSubmitted( new Date().valueOf() );
    toggleLoading( false );
    toggleInfoAlert( 'dataSaved' );
  }, [propertyManager.id, toggleInfoAlert, toggleLoading] );

  return (
    <CustomerAppEditView
      data={formatData()}
      isLoading={isLoadingData()}
      onSubmit={submitForm}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );
export default connect( mapStateToProps, mapDispatchToProps )( CustomerAppSettingsEdit );
