/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { handlePromise } from 'utils';
import SettingsActions from 'store/reducers/Settings';
import ApplyToModalFormView from 'views/Property/RoomType/Accommodation/Edit/RoomExpenses/ApplyToForm';
import { connect } from 'react-redux';
// import _get from 'lodash/get';
import TaskService from 'api/Task';

const ApplyToForm = ( {
  taskId,
  data,
  open,
  onClose,
  applyToOptions,
  toggleLoading,
  toggleInfoAlert,
  toggleErrorAlert,
  onReloadData,
} ) => {
  const [taskData, setTaskData] = useState( data );
  const [dataUpdated, setDataUpdated] = useState( new Date().valueOf() );
  const setApplyToProperty = useCallback(
    async ( value ) => {
      toggleLoading( true );
      const [error, response] = await handlePromise(
        value
          ? TaskService.updateTask( taskId, { applyTo: value } )
          : TaskService.updateTask( taskId, { applyTo: 'null' } ),
      );
      if ( !response.ok ) {
        toggleErrorAlert( error );
      }
      onReloadData();
      setDataUpdated( new Date().valueOf() );
      toggleLoading( false );
    },
    [taskId, toggleLoading, toggleErrorAlert, onReloadData],
  );

  const submitForm = useCallback(
    async ( formData ) => {
      toggleLoading( true );
      const formattedData = {
        extraCosts: formData.extraCost.map( ( { totalCost, _id, ...rest } ) => ( {
          ...rest,
        } ) ),
      };

      const [error, response] = await handlePromise(
        TaskService.updateTask( taskId, formattedData ),
      );
      if ( !response.ok ) {
        toggleLoading( false );
        return error;
      }

      onReloadData();
      setTaskData( data );
      toggleLoading( false );
      return toggleInfoAlert( 'dataSaved' );
    },
    [toggleInfoAlert, toggleLoading, data, onReloadData, taskId],
  );

  // useEffect( () => {
  //   if ( data.entity && data.entity.fees ) {
  //     const _currentFees = data.entity.fees;
  //     const _feeCategories = RUFeeCategories.map( ( item ) => {
  //       const feeFound = _currentFees.fees.find(
  //         ( fee ) => fee.name === item.name,
  //       );

  //       if ( feeFound ) {
  //         return {
  //           ...feeFound,
  //           enable: true,
  //         };
  //       }
  //       return {
  //         ...item,
  //         value: 0,
  //       };
  //     } );
  //     setFeeCategories( _feeCategories );
  //   }
  // }, [data.entity, data] );

  useEffect( () => {
    if ( data ) {
      setTaskData( data );
    }
  }, [data, dataUpdated] );

  return (
    <ApplyToModalFormView
      open={open}
      data={taskData}
      onSubmit={submitForm}
      onApplyToProperty={setApplyToProperty}
      onClose={onClose}
      applyToOptions={applyToOptions}
    />
  );
};

const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( null, mapDispatchToProps )( ApplyToForm );
