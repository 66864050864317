import React, { useContext, useMemo } from 'react';
import { HubContext } from 'components/Hub/context';
import { getInitials } from 'components/Hub/utils';
import icons from 'components/Hub/icons';
import classnames from 'classnames';
import moment from 'moment';

const fileImagesExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];

const MessageItem = ( { messageData, onToggleImageModal } ) => {
  const { selectedChannel, participantId } = useContext( HubContext );

  const { participant, sourceType } = useMemo( () => {
    let prt = { photo: null, name: 'Anonimous', initials: 'A' };
    let src = 'none';

    if ( !selectedChannel ) {
      return {
        participant: prt,
        sourceType: src,
      };
    }

    // PARTICIPANT
    if ( selectedChannel?.participants?.length ) {
      const p = selectedChannel.participants
        .find( ( item ) => item.id === messageData.participantId );
      prt = {
        photo: p?.photo || null,
        name: p?.name || 'Anonimous',
        initials: getInitials( p?.name || 'Anonimous' ),
      };
    }

    // SOURCES
    if ( selectedChannel?.sources?.length ) {
      const s = selectedChannel.sources
        .find( ( item ) => item.id === messageData.sourceId );

      src = s?.type || 'none';
    }

    return {
      participant: prt,
      sourceType: src,
    };
  }, [selectedChannel, messageData] );

  const filelist = useMemo( () => {
    if ( messageData.files && messageData.files.length ) {
      return messageData.files.map( ( file ) => ( {
        fileurl: file,
        filetype: file.split( '.' ).pop(),
      } ) );
    }
    return [];
  }, [messageData] );

  return (
    <div
      className="hub-message-list-item"
    >

      <div
        className={classnames( 'hub-message-body', {
          'to-right': messageData.participantId === participantId,
        } )}
      >
        <div className="hub-message-body-img">
          <div className="avatar rounded-circle aspect-ratio-1x1">
            {participant.photo
              ? (
                <img
                  alt={participant.initials}
                  src={participant.photo}
                  className="avatar rounded-circle"
                />
              ) : participant.initials}
          </div>
        </div>
        <div className="hub-message-body-content">
          <div className="hub-message-body-content-name">
            <div className={`hub-message-body-content-icon_source ${sourceType}`}>
              {icons[sourceType].icon}
            </div>
            {participant.name}
          </div>
          <div className="hub-message-body-content-message">
            {filelist.map( ( { fileurl, filetype } ) => (
              <div className="pt-2" key={fileurl}>
                {fileImagesExtensions.indexOf( filetype ) >= 0 ? (
                  <a
                    href="/"
                    className="hub-message-imagelink"
                    onClick={( e ) => {
                      e.preventDefault();
                      onToggleImageModal( fileurl );
                    }}
                  >
                    <img
                      src={fileurl}
                      alt="file"
                    />
                  </a>
                ) : (
                  <a href={fileurl} target="_blank" rel="noopener noreferrer" className="hub-message-filelink">
                    {`${filetype.toUpperCase()} `}
                  </a>
                )}
              </div>
            ) )}

            {messageData.content !== 'files' && messageData.content !== '' ? messageData.content : null}
          </div>
          <div className="hub-message-body-content-date">{moment( messageData.createdAt ).format( 'h:mm A' )}</div>
        </div>
      </div>
    </div>
  );
};

export default MessageItem;
