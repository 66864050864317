import React, { /* useCallback, */ useContext } from 'react';
import { HubContext } from 'components/Hub/context';
import {
  // Intl,
  // Switch,
  TextField,
} from 'components';

const ChannelHeader = () => {
  const { filterChannels, setFilterChannels/* , participantId */ } = useContext( HubContext );

  // const onChangeSwitch = useCallback( ( v ) => {
  //   setFilterChannels( {
  //     'filters[participantId]': v ? participantId : undefined,
  //     page: 1,
  //   } );
  // }, [participantId, setFilterChannels] );

  return (
    <header className="hub-channel-list-header">
      <TextField
        value={filterChannels?.['filters[name]'] || ''}
        onChange={( str ) => {
          setFilterChannels( {
            'filters[name]': str || undefined,
            page: 1,
          } );
        }}
        name="filter"
        size="lg"
        placeholder="filterChats"
        formGroupClass="hub-channel-list-header-filter"
        appendIconClass="fa-search"
      />
      {/* <div className="px-3">
        <label className="hub-channel-list-header-switch-label">
          <Intl id="hub.channelList.header.switch.label" />
          <Switch
            hideSideLabels
            formGroupClass="m-0"
            value={typeof filterChannels['filters[participantId]'] !== 'undefined'}
            inputOnChange={( e ) => {
              onChangeSwitch( e.target.checked );
            }}
          />
        </label>
      </div> */}
    </header>
  );
};
export default ChannelHeader;
