import React, {
  useRef, useState, useCallback, useEffect,
} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { handlePromise } from 'utils';
import _ from 'lodash';
import FiltersActions from 'store/reducers/Filters';
import { useFetchTableData } from 'hooks';
import TaskExpensesTableView from 'views/Property/RoomType/Accommodation/Edit/RoomExpenses/Dashboard/TaskExpensesTable';
import SettingsActions from 'store/reducers/Settings';
import TaskService from 'api/Task';
import { each } from 'utils/async';
import ApplyToForm from '../ApplyToForm';

const TaskExpensesTable = ( {
  entityId,
  toggleErrorAlert,
  toggleLoading,
  setFiltersError,
} ) => {
  const today = moment().startOf( 'day' );
  const initialReloadError = useRef( true );

  /// //
  const selectedRows = useRef( {} );
  const [selectedRowsChange, setSelectedRowsChange] = useState(
    new Date().valueOf(),
  );

  const [selectedId, setSelectedId] = useState( null );

  const [selectedRow, setSelectedRow] = useState( { extraCost: [] } );

  const [forceRender, setForceRender] = useState( true );

  const forceReload = useCallback(
    () => setSelectedRowsChange( new Date().valueOf() ),
    [],
  );

  /// /////////////////////////////////////////////////

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => TaskService.getTasks( {
      ...params,
      accommodation: entityId,
      expenses: true,
      from: params.from
        ? params.from
        : today.clone().subtract( 1, 'months' ).format( 'YYYY-MM-DD' ),
      till: params.till ? params.till : today.clone().format( 'YYYY-MM-DD' ),
    } ),
    callback: () => {
      initialReloadError.current = false;
    },
    callbackError: () => {
      if ( initialReloadError.current ) {
        setFiltersError( 'roomExpenses' );
        initialReloadError.current = false;
      }
    },
    toggleErrorAlert,
    deps: [entityId, selectedRowsChange],
  } );

  const setRoomExpenses = useCallback(
    async ( selectedItems ) => {
      if (
        !data
        || !data.data
        || !selectedItems
        || !Object.entries( selectedItems ).length
      ) {
        return false;
      }
      const selectedItemsList = [];

      Object.keys( selectedItems ).forEach( ( a ) => {
        selectedItemsList.push( { id: a, invoiced: selectedItems[a] } );
      } );

      const errors = [];
      toggleLoading();
      each(
        selectedItemsList,
        async ( item, cb ) => {
          const currentRoomExpenseOrig = data.data.find(
            ( re ) => re.id === item.id,
          );
          const currentRoomExpense = { ...currentRoomExpenseOrig };
          delete currentRoomExpense.propertyManager;
          delete currentRoomExpense.date;
          delete currentRoomExpense.updated;
          delete currentRoomExpense.created;
          delete currentRoomExpense.id;
          delete currentRoomExpense.invoice;
          delete currentRoomExpense.bookedAccommodation;
          delete currentRoomExpense.taskType;
          const [, response] = await handlePromise(
            TaskService.updateTask( item.id, {
              invoiced: `${item.invoiced}`,
              extraCosts: [null],
            } ),
          );
          if ( !response.ok ) {
            errors.push( item.id );
          }

          return cb();
        },
        () => {
          toggleLoading();
          if ( errors.length ) {
            toggleErrorAlert( 'dataSavedWithErrors' );
          } else {
            forceReload();
          }
        },
      );
    },
    [toggleErrorAlert, toggleLoading, forceReload, data],
  );

  const showApplyToModal = useCallback(
    async ( id, cellData ) => {
      setSelectedId( id );
      setSelectedRow( cellData );
    },
    [],
  );

  const hideApplyToModal = useCallback(
    async () => {
      setSelectedId( null );
      setSelectedRow( { extraCost: [] } );
    },
    [],
  );

  /// /////////////////////////////////////////////////

  useEffect( () => {
    selectedRows.current = {};

    if ( data && data.data && data.data.length ) {
      data.data.forEach( ( item ) => {
        selectedRows.current[item.id] = item.invoice || item.invoiced || false;
      } );
    }
    setForceRender( false );
    const timer = setTimeout( () => {
      setForceRender( true );
    }, 140 );

    return () => {
      clearTimeout( timer );
    };
  }, [data] );

  const toggleRowChecked = useCallback(
    ( id, value ) => {
      selectedRows.current[id] = value;
      //  forceReload();
      setRoomExpenses( { [id]: value } );
    },
    [setRoomExpenses],
  );

  const toggleAllRowsChecked = useCallback( () => {
    let flagForAll = true;
    // eslint-disable-next-line no-unused-vars, max-len
    if (
      data.data
      && Object.entries( selectedRows.current ).filter( ( [, value] ) => value )
        .length === data.data.length
    ) {
      flagForAll = false;
    }

    selectedRows.current = {};
    _.map( data.data, 'id' ).forEach( ( id ) => {
      selectedRows.current[id] = flagForAll;
    } );
    // forceReload();
    setRoomExpenses( selectedRows.current );
  }, [data, setRoomExpenses] );

  const applyToValues = [
    { name: 'owner', label: 'owner', id: 'owner' },
    {
      name: 'propertyManager',
      label: 'propertyManager',
      id: 'propertyManager',
    },
    { name: 'client', label: 'client', id: 'client' },
  ];

  useEffect( () => {
    if ( selectedId ) {
      const rowFound = data.data.find( ( item ) => item.id === selectedId );
      if ( rowFound ) {
        setSelectedRow( rowFound );
      }
    }
  }, [data, selectedId] );

  // const setApplyToProperty = useCallback(
  //   async ( value, id ) => {
  //     toggleLoading( true );
  //     const [error, response] = await handlePromise(
  //       value
  //         ? TaskService.updateTask( id, { applyTo: value, extraCosts: [null] } )
  //         : TaskService.updateTask( id, { applyTo: 'null', extraCosts: [null] } ),
  //     );
  //     if ( !response.ok ) {
  //       toggleErrorAlert( error );
  //     }
  //     forceReload();
  //     toggleLoading( false );
  //   },
  //   [toggleLoading, toggleErrorAlert, forceReload],
  // );
  return (
    <>
      <TaskExpensesTableView
        data={forceRender ? data : { data: [] }}
        onFetchData={fetchData}
        isLoading={dataLoading}
        selectedRows={selectedRows.current}
        onToggleRowChecked={toggleRowChecked}
        onToggleAllRowsChecked={toggleAllRowsChecked}
        applyToOptions={applyToValues}
        onApplyToChange={showApplyToModal}
      />
      <ApplyToForm
        taskId={selectedId}
        data={selectedRow}
        applyToOptions={applyToValues}
        open={Boolean( selectedId )}
        onClose={hideApplyToModal}
        onReloadData={forceReload}
        dataUpdated={selectedRowsChange}
      />
    </>
  );
};

const mapStateToProps = ( { router } ) => ( {
  searchParams: _.get( router, 'location.search', '' ),
} );

const mapDispatchToProps = {
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  setFiltersError: FiltersActions.setFiltersError,
};

export default connect( mapStateToProps, mapDispatchToProps )( TaskExpensesTable );
