import React from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import {
  AsyncTable,
  TableGenericFilter,
  Intl,
  TableSelectFilter,
  Switch,
  TableCellList,
  Button,
} from 'components';

export default React.memo(
  ( {
    data,
    isLoading,
    defaultFilters,
    selectedRows,
    onFetchData,
    onFetchAccommodations,
    onToggleRowChecked,
    onToggleAllRowsChecked,
    setKeywordTasksExpenses,
    setAccommodationTasksExpenses,
    onApplyToChange,
  } ) => (
    <AsyncTable
      id="taskExpenses"
      uncenterHeaders
      containerClassName="no-shadow mb-0"
      data={data.data}
      total={data.elementsTotal}
      isLoading={isLoading}
      disableSorting
      defaultFilters={defaultFilters}
      onFetchData={onFetchData}
      customFilters={() => (
        <>
          <h3 className="mb-3">
            <Intl id="taskExpenses" />
          </h3>
        </>
      )}
      customToggleFilters={( { filters, setFilter } ) => (
        <Row>
          <Col md={8} lg={3} className="pb-3">
            <TableGenericFilter
              label="search"
              input
              filters={filters}
              setFilter={( name, value ) => {
                setKeywordTasksExpenses( value );
                setFilter( name, value );
              }}
            />
          </Col>
          <Col md={8} lg={3} className="pb-3">
            <TableSelectFilter
              name="accommodation"
              label="accommodation"
              placeholder="all"
              filters={filters}
              isClearable
              defaultOptions
              loadOptions={onFetchAccommodations}
              formatFn={( dataClient ) => dataClient?.accommodations || []}
              setFilter={( name, value ) => {
                if ( value ) {
                  setAccommodationTasksExpenses( value.value );
                } else {
                  setAccommodationTasksExpenses( null );
                }
                setFilter( name, value );
              }}
            />
          </Col>
        </Row>
      )}
      getRowProps={( row ) => ( {
        className: row.original.type === 'issue' ? 'bg-light-red' : '',
      } )}
      columns={[
        {
          Header: (
            <div className="text-left">
              <span
                className="d-inline-block"
                style={{ position: 'relative', top: 2 }}
              >
                <Switch
                  name="header"
                  formGroupClass="m-0 pl-3 d-inline-block"
                  disabled={!data.data.length}
                  checked={
                    data.data
                    && data.data.length
                    && data.data.length
                      === Object.entries( selectedRows ).filter( ( [, value] ) => value )
                        .length
                  }
                  hideSideLabels
                  onChange={onToggleAllRowsChecked}
                />
              </span>
              <span className="align-top ml-1 d-inline-block">
                <Intl id="invoiced" />
              </span>
            </div>
          ),
          id: 'check',
          accessor: 'id',
          maxWidth: 155,
          Cell: ( { cell } ) => {
            const title = `${cell.row.original.id.slice(
              cell.row.original.id.length - 6,
              cell.row.original.id.length,
            )}`;
            return (
              <div className="text-left">
                <Switch
                  disabled={!cell.row.original.applyTo}
                  name={cell.value}
                  formGroupClass="m-0 pl-3 d-inline-block"
                  checked={selectedRows[cell.value]}
                  hideSideLabels
                  onChange={( e ) => {
                    onToggleRowChecked( cell.value, e.target.checked );
                  }}
                />
                <b className="text-uppercase pl-2">{title}</b>
              </div>
            );
          },
        },
        {
          Header: 'accommodation',
          accessor: 'accommodation',
          Cell: ( { cell } ) => cell.value?.name || '-',
        },
        {
          Header: 'category',
          accessor: 'taskType.name',
        },
        {
          Header: 'date',
          accessor: 'date',
          Cell: ( { cell } ) => ( cell.value
            ? moment.utc( cell.value ).format( 'DD-MM-YYYY hh:mma' )
            : '' ),
        },
        {
          Header: 'applyToCost',
          accessor: 'id',
          maxWidth: 200,
          Cell: ( { cell } ) => (
            <div className="text-center">
              {!cell.row.original.extraCost ? <Intl id="nocosts" />
                : (
                  <Button
                    size="sm"
                    color="primary"
                    outline
                    type="button"
                    className="px-4 py-2"
                    onClick={() => onApplyToChange( cell.value, cell.row.original )}
                    title="edit"
                  />
                )}
            </div>
          ),
        },
        {
          Header: 'cost',
          accessor: 'taskType',
          Cell: ( { cell } ) => <span>{cell.value.cost || '-'}</span>,
        },
        {
          Header: 'extraCost',
          accessor: 'extraCost',
          Cell: ( { cell } ) => (
            <TableCellList
              list={cell.value}
              limit={6}
              render={( ec, k ) => (
                <div className="small" key={k}>
                  {`${ec.description} - ${ec.cost} `}
                  &euro;
                </div>
              )}
            />
          ),
        },
      ]}
    />
  ),
);
