import React, {
  createContext, useState,
} from 'react';

const tabsList = [
  { name: 'crm.contactInfo.general' },
  { name: 'crm.contactInfo.communications' },
  { name: 'crm.contactInfo.activity' },
  { name: 'crm.contactInfo.business' },
  { name: 'crm.contactInfo.tasks' },
  { name: 'crm.contactInfo.mail' },
  { name: 'crm.contactInfo.notes' },
  { name: 'crm.contactInfo.calls' },
];

const defaulTab = 'crm.contactInfo.communications';

export const ContactEditContext = createContext( {
  contactId: null,
  path: '',
  tabsList,
  selectedTab: defaulTab,
  setSelectedTab: () => {},
  data: {},
  loading: false,
  reloadData: () => {},
} );

const ContactEditContextProvider = ( {
  children, contactId, path, data, loading, reloadData,
} ) => {
  const [selectedTab, setSelectedTab] = useState( defaulTab );

  return (
    <ContactEditContext.Provider
      value={{
        contactId,
        path,
        tabsList,
        selectedTab,
        setSelectedTab,
        data,
        loading,
        reloadData,
      }}
    >
      {children}
    </ContactEditContext.Provider>
  );
};
export default ContactEditContextProvider;
