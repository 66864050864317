import React from 'react';
import { Container } from 'reactstrap';
import Hub from 'components/Hub';

const Communications = () => (
  <Container fluid>
    <Hub channelType="crm" />
  </Container>

);

export default Communications;
