import React from 'react';
import classnames from 'classnames';
import HubContextProvider from './context';
import ChannelList from './channel-list';
import MessageList from './message-list';
import HubProps from './hub-props';

const Hub = ( { channelType = 'operations', className, customChannelsFilters } ) => (
  <HubContextProvider channelType={channelType} customChannelsFilters={customChannelsFilters}>
    <div className={classnames( 'hub', {
      'for-crm': channelType === 'crm',
      'for-operations': channelType === 'operations',
    }, className )}
    >
      <div className="hub-container">
        <ChannelList />
        <MessageList />
        {channelType === 'crm' ? null : <HubProps />}
      </div>
    </div>
  </HubContextProvider>
);
export default Hub;
