import React from 'react';
import WhatsappIcon from './whatsapp';
import SmsIcon from './sms';
import EmailIcon from './email';
import AllIcon from './all';
import OtherIcon from './other';
import ManagerIcon from './manager';

const icons = {
  whatsapp: {
    name: 'Whatsapp',
    icon: <WhatsappIcon />,
  },
  sms: {
    name: 'SMS',
    icon: <SmsIcon />,
  },
  email: {
    name: 'Email',
    icon: <EmailIcon />,
  },
  manager: {
    name: 'Manager',
    icon: <ManagerIcon />,
  },
  other: {
    name: 'Other',
    icon: <OtherIcon />,
  },
  none: {
    name: 'All',
    icon: <AllIcon />,
  },
};

export default icons;
