import React from 'react';
import { Row, Col } from 'reactstrap';

import {
  ModalForm,
  Select,
  Intl,
  LocalTable,
} from 'components';
import { canModify } from 'config/Ability';

export default React.memo(
  ( {
    applyToOptions, data, isLoading, open, onClose, onSubmit, onApplyToProperty,
  } ) => (
    <ModalForm
      initialValues={data}
      title="applyToCost"
      showErrorsInFooter
      isLoading={isLoading}
      bodyClassName="overflow-visible"
      open={open}
      onClose={onClose}
      zIndex={9999}
      onSubmit={canModify( 'task' ) ? onSubmit : () => {}}
      formEnabled={canModify( 'task' )}
      body={( form ) => (
        <>
          <Row>
            <Col>
              <label className="form-control-label">
                <Intl id="assingAllexpensesTo" />
              </label>
            </Col>
            <Col>
              <Select
                options={applyToOptions}
                translateOptions
                onChange={onApplyToProperty}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="form-control-label">
                <Intl id="individualAssignment" />
              </label>
            </Col>
          </Row>
          <LocalTable
            data={
                form.getState().values
                  ? form.getState().values.extraCost
                  : data.extraCost
              }
            onlyTable
            columns={[
              {
                Header: 'description',
                accessor: 'description',
              },
              {
                Header: 'applyTo',
                accessor: 'applyTo',
                Cell: ( { cell } ) => (
                  <Select
                    value={cell.value}
                    options={applyToOptions}
                    translateOptions
                    onChange={( val ) => {
                      form.change(
                        `extraCost[${cell.row.index}].applyTo`,
                        val,
                      );
                    }}
                  />
                ),
              },
            ]}
          />
        </>
      )}
    />
  ),
);
