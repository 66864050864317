import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import {
  Row, Col, Button, Collapse,
} from 'reactstrap';
import TaskActions from 'store/reducers/Task';
import {
  Separator, Intl, BookingOpenLockerBtns, BookingOpenLockerModal,
} from 'components';
import formatBookinglId from 'utils/bookingId';
import TaskDetail from './TaskDetail';
import ServiceDetail from './ServiceDetail';

const BookingView = ( {
  data = {},
  toggleTaskEditModal,
  userCurrency,
  userBookingReference,
} ) => {
  const [openDoorModalOpen, setOpenDoorModal] = useState( false );
  const [accommodationInfoOpen, setAccommodationInfo] = useState( true );
  const [tasksInfoOpen, setTasksInfo] = useState( true );
  const [servicesInfoOpen, setServicesInfo] = useState( true );
  const doorTypeToOpen = useRef( null );
  const doorIdToOpen = useRef( null );

  const country = _.get( data, 'currentAccommodation.roomType.property.propertyManagerCityZone.propertyManagerCity.city.state.country.name' );
  const city = _.get( data, 'currentAccommodation.roomType.property.propertyManagerCityZone.propertyManagerCity.city.name' );

  const onOpenTaskEditModal = useCallback( ( { id } = {}, extraData ) => {
    // if ( selectedChannelData.current.id && !id ) {
    //   const accommodation = selectedChannelData.current.currentAccommodation;
    //   if ( accommodation ) {
    //     taskToEditData.current = selectedChannelData.current.id ? {
    //       accommodation: {
    //         value: accommodation.id,
    //         label: accommodation.name,
    //       },
    //       bookedAccommodation: selectedChannelData.current.id,
    //     } : null;
    //   }
    //   toggleTaskEditModal( null, taskToEditData.current );
    // } else {
    if ( extraData ) {
      toggleTaskEditModal( null, extraData );
    } else {
      toggleTaskEditModal( id );
    }

    // }
  }, [toggleTaskEditModal] );

  const openOpenDoorModal = ( type, lockerId ) => {
    doorTypeToOpen.current = type;
    doorIdToOpen.current = lockerId;
    setOpenDoorModal( true );
  };

  const closeOpenDoorModal = () => {
    setOpenDoorModal( false );
  };

  const toggleAccommodationInfo = useCallback( () => {
    setAccommodationInfo( !accommodationInfoOpen );
  }, [accommodationInfoOpen] );

  const toggleTasksInfo = useCallback( () => {
    setTasksInfo( !tasksInfoOpen );
  }, [tasksInfoOpen] );

  const toggleServicesInfo = useCallback( () => {
    setServicesInfo( !servicesInfoOpen );
  }, [servicesInfoOpen] );

  return (
    <>
      <header className="hub-props-header">
        {( data && moment.utc( data.till ).diff( moment.utc().startOf( 'day' ) ) < 0 )
          ? <Separator leftIsActive rightIsActive /> : null}
        {( data && moment.utc( data.from ).diff( moment.utc().startOf( 'day' ) ) > 0 )
          ? <Separator leftIsActive={false} rightIsActive={false} /> : null}
        {( data && moment.utc( data.from ).diff( moment.utc().startOf( 'day' ) ) <= 0
          && moment.utc( data.till ).diff( moment.utc().startOf( 'day' ) ) >= 0 )
          ? <Separator leftIsActive rightIsActive={false} /> : null}
        {data.externalId
          ? (
            <Link
              target="_blank"
              to={`/bookings/list/${data.id}`}
              className="font-weight-bold text-darker text-bold text-2xsm ml-2 text-uppercase"
            >
              {`RES. ${formatBookinglId( { ...data, userBookingReference } )}`}
            </Link>
          ) : null}
      </header>
      <section className="hub-props-body">
        <div className="p-4">
          <Row>
            <Col lg={6}>
              <p className="text-2xsm text-uppercase font-weight-500 m-0"><Intl id="status" /></p>
              <p className="text-sm text-darker text-bold mb-3">
                {data.status ? <Intl id={data.status} /> : '---'}
              </p>
            </Col>
            <Col lg={6}>
              <p className="text-2xsm text-uppercase font-weight-500 m-0"><Intl id="source" /></p>
              <p className="text-sm text-darker text-bold mb-3">{_.get( data, 'ota', '---' ) || '---'}</p>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <p className="text-2xsm text-uppercase font-weight-500 m-0"><Intl id="checkIn" /></p>
              <p className="text-sm text-darker text-bold mb-3">
                {data.from ? `${moment.utc( data.from ).format( 'DD/MMM/YY' )} ${data.checkInTime || ''}` : ''}
              </p>
            </Col>
            <Col lg={4}>
              <p className="text-2xsm text-uppercase font-weight-500 m-0"><Intl id="checkOut" /></p>
              <p className="text-sm text-darker text-bold mb-3">
                {data.till ? `${moment.utc( data.till ).format( 'DD/MMM/YY' )} ${data.checkOutTime || ''}` : ''}
              </p>
            </Col>
            <Col lg={4}>
              <p className="text-2xsm text-uppercase font-weight-500 m-0"><Intl id="nights" /></p>
              <p className="text-sm text-darker text-bold mb-3">{_.get( data, 'lapse', [] ).length || ''}</p>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <p className="text-2xsm text-uppercase font-weight-500 m-0"><Intl id="guests" /></p>
              <p className="text-sm text-darker text-bold mb-0">
                {data.capacity || ''}
              </p>
            </Col>
            <Col lg={8}>
              <p className="text-2xsm text-uppercase font-weight-500 m-0"><Intl id="customer" /></p>
              <p className="text-sm text-darker text-bold mb-0">
                {_.get( data, 'owner.firstName' )}
                {' '}
                {_.get( data, 'owner.lastName' )}
              </p>
            </Col>
          </Row>
        </div>
        <hr className="divider m-0" />
        <div className="px-4 py-3">
          <div className="accordion">
            <Row
              role="tab"
              onClick={toggleAccommodationInfo}
              aria-expanded={accommodationInfoOpen}
            >
              <Col className="d-flex justify-content-between">
                <div>
                  <i className="app-icon-address text-darker text-bold" />
                  <span className="font-weight-bold text-darker text-bold text-2xsm text-uppercase ml-2">
                    <Intl id="accommodation" />
                  </span>
                </div>
                <i
                  className={classnames( 'fa p-2', {
                    'fa-chevron-down': accommodationInfoOpen,
                    'fa-chevron-up': !accommodationInfoOpen,
                  } )}
                />
              </Col>
            </Row>

            <Collapse role="tabpanel" isOpen={accommodationInfoOpen}>
              <hr className="divider my-3" />
              <Row>
                <Col>
                  <p className="text-2xsm text-uppercase font-weight-500 m-0">
                    {_.get( data, 'currentAccommodation.name' )}
                  </p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-darker text-bold mb-0"
                    href={`https://www.google.com/maps/place/${_.get( data, 'currentAccommodation.roomType.property.address' )} ${city || ''} ${country || ''}`}
                  >
                    {_.get( data, 'currentAccommodation.roomType.property.address' )}
                    {city ? ', ' : ' '}
                    {city}
                    {city && country ? ' - ' : ''}
                    {country}
                  </a>
                  <p className="text-sm text-darker text-bold mb-0">
                    {_.get( data, 'currentAccommodation.number' ) ? `Apt: ${_.get( data, 'currentAccommodation.number' )}` : ''}
                  </p>
                </Col>
              </Row>

              <BookingOpenLockerBtns
                data={data}
                classNames={{ title: 'text-2xsm text-uppercase mt-4' }}
                onOpenDoorModal={openOpenDoorModal}
              />
              <BookingOpenLockerModal
                data={data}
                doorType={doorTypeToOpen.current}
                lockerId={doorIdToOpen.current}
                modalOpened={openDoorModalOpen}
                onCloseModal={closeOpenDoorModal}
              />
            </Collapse>
          </div>
        </div>
        <hr className="divider m-0" />

        {data.services && data.services.length > 0 && (
        <>
          <div className="px-4 py-3">
            <div className="accordion">
              <Row
                role="tab"
                onClick={toggleServicesInfo}
                aria-expanded={servicesInfoOpen}
              >
                <Col className="d-flex justify-content-between">
                  <div>
                    <i className="app-icon-rocket text-darker text-bold" />
                    <span className="font-weight-bold text-darker text-bold text-2xsm text-uppercase ml-2">
                      <Intl id="services" />
                    </span>
                  </div>
                  <i
                    className={classnames( 'fa p-2', {
                      'fa-chevron-down': servicesInfoOpen,
                      'fa-chevron-up': !servicesInfoOpen,
                    } )}
                  />
                </Col>
              </Row>

              <Collapse role="tabpanel" isOpen={servicesInfoOpen}>
                <hr className="divider my-3" />
                <Row>
                  <Col>
                    {_.map( data.services, ( service ) => (
                      <div key={service.id}>
                        <ServiceDetail service={service} userCurrency={userCurrency} />
                        <hr className="divider my-3" />
                      </div>
                    ) )}
                  </Col>
                </Row>
              </Collapse>
            </div>
          </div>
          <hr className="divider m-0" />
        </>
        )}

        {data.tasks && data.tasks.length > 0 && (
          <div className="px-4 py-3">
            <div className="accordion">
              <Row
                role="tab"
                onClick={toggleTasksInfo}
                aria-expanded={tasksInfoOpen}
              >
                <Col className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <i className="ni ni-bullet-list-67 text-darker text-bold" />
                    <span className="font-weight-bold text-darker text-bold text-2xsm text-uppercase ml-2">
                      <Intl id="tasksIssues" />
                    </span>
                  </div>
                  <i
                    className={classnames( 'fa p-2', {
                      'fa-chevron-down': tasksInfoOpen,
                      'fa-chevron-up': !tasksInfoOpen,
                    } )}
                  />
                </Col>
              </Row>

              <Collapse role="tabpanel" isOpen={tasksInfoOpen}>
                <hr className="divider my-3" />
                {_.map( data.tasks, ( task ) => (
                  <div key={task.id}>
                    <TaskDetail task={task} onOpenTaskEditModal={onOpenTaskEditModal} />
                    <hr className="divider my-3" />
                  </div>
                ) )}
              </Collapse>
            </div>
          </div>
        )}

      </section>
      {!!_.get( data, 'id' )
            && (
            <footer className="hub-props-footer">
              <Button outline onClick={() => onOpenTaskEditModal( {}, { ...data, type: 'issue' } )} className="py-1">
                <i className="app-icon-warning" />
                <span><Intl id="reportIssue" /></span>
              </Button>
            </footer>
            )}
    </>
  );
};

const mapStateToProps = ( {
  user,
} ) => ( {
  userCurrency: _.get( user, 'settings.currency' ),
  userBookingReference: _.get( user, 'settings.bookingReference' ),
} );

const mapDispatchToProps = ( {
  toggleTaskEditModal: TaskActions.toggleEditModal,
} );

export default connect( mapStateToProps, mapDispatchToProps )( BookingView );
