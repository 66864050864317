/* eslint-disable import/prefer-default-export */
export const getNewExpenses = ( array1 = [], array2 = [] ) => {
  const newExpenses = array1.filter(
    ( element ) => !array2.filter( ( item ) => item.name === element.name ).length,
  );

  const oldExpenses = array2.filter(
    ( element ) => array1.filter( ( item ) => item.name === element.name ).length,
  ).map( ( { id, totalCost, ...rest } ) => rest );

  return { newExpenses, oldExpenses };
};
