import moment from 'moment';

export const getInitials = ( str ) => str.split( ' ' ).map( ( n ) => n[0] ).join( '' ).toUpperCase()
  .charAt( 0 );

export const isSameDay = ( currentMessage, diffMessage ) => {
  if ( !diffMessage ) return false;

  const currentCreatedAt = moment.utc( new Date( currentMessage?.createdAt ) );
  const diffCreatedAt = moment.utc( new Date( diffMessage?.createdAt ) );

  if ( !currentCreatedAt.isValid() || !diffCreatedAt.isValid() ) return false;

  return currentCreatedAt.isSame( diffCreatedAt, 'day' );
};
