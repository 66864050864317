import React, { useContext } from 'react';
import ContactCommunicationsView from 'views/CRM/Contact/Edit/Communications';
import { ContactEditContext } from '../context';

const CommunicationsCalls = () => {
  const {
    contactId,
  } = useContext( ContactEditContext );

  return (
    <ContactCommunicationsView contactId={contactId} />
  );
};

export default CommunicationsCalls;
