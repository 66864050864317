import React, { useContext, useState } from 'react';
import { HubContext } from 'components/Hub/context';
import {
  Button,
} from 'reactstrap';
import { TextField } from 'components';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import SourceSelector from '../source-selector';

const MessageSender = () => {
  const { enabledSubmitMessage, submitMessage, loadingMessage } = useContext( HubContext );

  const [message, setMessage] = useState( '' );

  const sendTextMessage = () => {
    submitMessage( message, null );
    setMessage( '' );
  };
  const sendFileMessage = ( file ) => {
    submitMessage( message || '', file );
    setMessage( '' );
  };

  return (
    <div className={classNames( 'hub-message-sender', {
      'loading-message': loadingMessage,
      'disabled-submit': !enabledSubmitMessage,
    } )}
    >
      <div>
        <SourceSelector />
      </div>
      <div>
        <Dropzone
          accept="image/*"
          maxFiles={1}
          disabled={!enabledSubmitMessage}
          onDrop={( files ) => {
            if ( files && files[0] ) {
              const reader = new FileReader();
              reader.onload = ( ) => {
                sendFileMessage( files[0] );
              };
              reader.readAsDataURL( files[0] );
            }
          }}
        >
          {( { getRootProps, getInputProps } ) => (
            <>
              <i
                className={classNames( 'app-icon-attachment text-primary', {
                  'cursor-pointer': enabledSubmitMessage,
                } )}
                {...getRootProps()}
              />
              <input {...getInputProps()} />
            </>
          )}
        </Dropzone>
      </div>
      <div>
        <TextField
          name="chat-send-message"
          value={message}
          onChange={setMessage}
          size="lg"
          formGroupClass="hub-message-sender-textarea"
          rows="1"
          type="textarea"
          resize="none"
          placeholder="typeMessage"
          disabled={!enabledSubmitMessage}
          onKeyDown={( e ) => {
            if ( e.keyCode === 13 && e.shiftKey === false ) {
              e.preventDefault();
              sendTextMessage();
            }
          }}
        />
      </div>
      <div>
        <Button
          className="btn-icon-only rounded-circle"
          color="primary"
          type="button"
          disabled={!enabledSubmitMessage}
          onClick={sendTextMessage}
        >
          <i className="fa fa-paper-plane" />
        </Button>
      </div>
    </div>
  );
};

export default MessageSender;
