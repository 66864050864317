import React, {
  useState, useEffect, useCallback, useRef, useMemo,
} from 'react';
import { Row, Col } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

import {
  TextField,
  Select,
  Intl,
  Form,
  TaskComments,
  TaskTemporalFormBtns,
  AsyncSelect,
  UserAvatar,
  Datepicker,
  ImagesDropzoneGeneric,
  // CustomField,
  CostList,
  BookingOpenLockerBtns,
  BookingOpenLockerModal,
  ExpensesList,
} from 'components';
import {
  taskPriorities,
  taskStatuses,
  taskTypes as typeOfTaskTypes,
} from 'config/Constants';
import { getInitials, getResourceName } from 'utils';
import { canModify } from 'config/Ability';
import useFetch from 'hooks/useFetch';
import PlacesService from 'api/Places';
import { loadTeams } from './utils';

export default React.memo(
  ( {
    data,
    // onSubmitNewExpense,
    // customFields,
    onSubmit,
    onAssign,
    onDeleteImage,
    onAddImage,
    onSubmitExtraCosts,
    onSubmitComment,
    onDeleteComment,
    taskTypes,
    isLoadingTaskTypes,
    onSubmitExpenses,
    toggleLoading,
  } ) => {
    const [enabledField, setEnabledField] = useState( null );
    const [taskTypeCost, setTaskTypeCost] = useState( 0 );
    const onSubmitFn = canModify( 'task' ) ? onSubmit : () => {};
    const onSubmitExtraCostsFn = canModify( 'task' )
      ? onSubmitExtraCosts
      : () => {};
    const onAssignFn = canModify( 'task' ) ? onAssign : () => {};
    const [openModalOpen, setOpenModal] = useState( false );
    const doorTypeToOpen = useRef( null );
    const doorIdToOpen = useRef( null );
    const [selectedTaskType, setSelectedTaskType] = useState( );
    const [availableEmployees, setavailableEmployees] = useState( [] );
    const [availableTeams, setavailableTeams] = useState( [] );
    const [selectedTeam, setSelectedTeam] = useState( '' );
    const [zone, setZone] = useState( );
    const [date, setDate] = useState( data.date );

    const formatOptions = ( _options ) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      _options.map( ( op ) => ( {
        id: op.id,
        name: `${op.firstName} ${op.lastName}`,
      } ) );

    useEffect( () => {
      const getTeams = async () => {
        toggleLoading( true );
        const params = {
          elementsPerPage: 100,
          date: data.date,
          taskTypes: [selectedTaskType],
        };
        if ( selectedTaskType ) {
          params.taskTypes = [selectedTaskType];
        }
        if ( zone ) {
          params.zone = zone;
        }
        const { data: teamData } = await loadTeams( params );
        setavailableTeams( teamData );
        toggleLoading( false );
      };
      if ( selectedTaskType ) {
        getTeams();
      }
    }, [data.date, zone, selectedTaskType, toggleLoading] );

    useEffect( () => {
      if ( availableTeams.length && selectedTeam ) {
        const selectedTeamData = availableTeams.filter( ( team ) => team.id === selectedTeam );
        if ( selectedTeamData.length ) {
          setavailableEmployees( formatOptions( selectedTeamData[0]?.members ) );
        }
      }
    }, [availableTeams, selectedTeam] );

    useEffect( () => {
      if ( data && data.team ) {
        setSelectedTeam( data.team );
      }
    }, [data] );

    useEffect( () => {
      if ( data.accommodation && data.accommodation.zone ) {
        setZone( data.accommodation.zone );
      }
      if ( data.taskType && data.taskType.value ) {
        setSelectedTaskType( data.taskType.value );
      }
    }, [data.accommodation, data.taskType] );

    const setCostByTaskType = useCallback(
      ( taskTypeId ) => {
        if ( taskTypes.length ) {
          let newCost = 0;
          taskTypes.forEach( ( taskTypeParent ) => {
            if ( taskTypeParent.options.length ) {
              const taskTypeChildOption = taskTypeParent.options.filter(
                ( taskTypeChild ) => taskTypeChild.id === taskTypeId,
              );
              if ( taskTypeChildOption[0] ) {
                newCost = taskTypeChildOption[0]?.cost || 0;
              }
            }
          } );
          setTaskTypeCost( newCost );
        }
      },
      [taskTypes],
    );

    useEffect( () => {
      if ( data && data.taskType && data.taskType.value ) {
        setCostByTaskType( data.taskType.value );
      }
      if ( data && data.date ) {
        setDate( data.date );
      }
    }, [setCostByTaskType, data] );

    const openOpenDoorModal = ( { type, lockerId } ) => {
      doorTypeToOpen.current = type;
      doorIdToOpen.current = lockerId;
      setOpenModal( true );
    };

    const closeOpenDoorModal = () => {
      setOpenModal( false );
    };

    const dataInitial = useMemo( () => {
      const newData = {
        ...data,
        taskTypeCost,
        assignedTo: data.assignedTo
          ? {
            id: data.assignedTo?.value,
            label: data.assignedTo.label,
          }
          : {},
      };

      return newData;
    }, [data, taskTypeCost] );

    const [taskEstimatedTime, setTaskEstimatedTime] = useState( null );

    useEffect( () => {
      if ( data && data.taskEstimatedTime ) {
        setTaskEstimatedTime( data.taskEstimatedTime );
      } else {
        let newTaskEstimatedTime = null;
        taskTypes.forEach( ( { options } ) => {
          if ( options ) {
            const [opSelected] = options.filter( ( { id } ) => id === selectedTaskType );
            if ( opSelected ) {
              newTaskEstimatedTime = opSelected?.estimatedTime || null;
            }
          }
        } );
        if ( newTaskEstimatedTime ) {
          setTaskEstimatedTime( newTaskEstimatedTime );
        }
      }
    }, [selectedTaskType, taskTypes, data] );

    const [places] = useFetch( {
      promise: () => PlacesService.getList( {
        elementsPerPage: 99,
      } ),
      reInit: true,
    } );

    const place = useMemo( () => {
      if ( data && data.placeId && places && places.data ) {
        const newPlace = places.data.find( ( plc ) => plc._id === data.placeId );
        return {
          value: newPlace?._id || '',
          label: newPlace?.name || '',
        };
      }
      return null;
    }, [places, data] );

    console.log( 'dataInitial', dataInitial );

    return (
      <>
        <Form
          onSubmit={onSubmitFn}
          initialValues={{ ...dataInitial, taskEstimatedTime, place }}
          showErrorsInFooter
          body={( form ) => {
            const disabled = data.status !== 'open' && data.status !== 'assigned';
            const isClosed = data.status === 'closed';
            const noReassign = isClosed
              || data.status === 'in_progress'
              || data.status === 'resolved';

            useEffect( () => {
              if ( dataInitial ) {
                console.log( 'tasktype inital', dataInitial.taskType );
                form.restart( { ...dataInitial, taskEstimatedTime } );
              }
              // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [] );
            return (
              <Row className="pl-3">
                <Col lg={8} className="border-right">
                  <Row className="mb-4">
                    <Col md={4}>
                      <Select
                        field="type"
                        label="type"
                        labelClass="font-weight-bold"
                        translateOptions
                        options={typeOfTaskTypes}
                        onChange={() => onSubmitFn( form.getState().values, 'type', 'type' )}
                      />
                    </Col>
                    <Col md={8}>
                      <AsyncSelect
                        field="accommodation"
                        label="accommodation"
                        labelClass="font-weight-bold"
                        isDisabled
                      />
                      <AsyncSelect
                        field="place"
                        label="place"
                        labelClass="font-weight-bold"
                        isDisabled
                      />
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col md={7}>
                      <Select
                        field="taskType"
                        label="category"
                        labelClass="font-weight-bold"
                        options={taskTypes}
                        isLoading={isLoadingTaskTypes}
                        optgroup
                        onChange={( v ) => {
                          setCostByTaskType( v );
                          onSubmitFn(
                            form.getState().values,
                            'taskType',
                            'taskType',
                          );
                          setSelectedTaskType( v );
                        }}
                      />
                    </Col>
                    <Col md={5}>
                      <TextField
                        field="taskEstimatedTime"
                        label="estimatedTime"
                        type="number"
                        onChange={setTaskEstimatedTime}
                        onBlur={( ) => {
                          onSubmitFn(
                            { taskEstimatedTime },
                            'taskEstimatedTime',
                            'taskEstimatedTime',
                          );
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col xs="auto">
                      <div style={{ width: 90 }}>
                        <TextField
                          field="taskTypeCost"
                          label="fixedCost"
                          type="number"
                          disabled
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <ExpensesList
                        value={data && data.extraExpenses ? data.extraExpenses : []}
                        extraCosts={data && data.extraCost ? data.extraCost : []}
                        form={form}
                        onSubmitExpenses={onSubmitExpenses}
                        // onSubmit={onSubmitNewExpense}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col>
                      <CostList
                        label="assignedCosts"
                        value={data && data.extraCost ? data.extraCost : []}
                        onSubmit={onSubmitExtraCostsFn}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      {enabledField === 'description' ? (
                        <>
                          <TextField
                            field="description"
                            label="description"
                            type="textarea"
                            labelClass="font-weight-bold"
                            rows={3}
                          />
                          <TaskTemporalFormBtns
                            onSave={() => onSubmitFn(
                              form.getState().values,
                              'description',
                              'description',
                              () => setEnabledField( null ),
                            )}
                            onClickCancel={() => setEnabledField( null )}
                          />
                        </>
                      ) : (
                        <div
                          style={{ minHeight: '6rem' }}
                          className={classnames( {
                            'cursor-pointer': !disabled,
                          } )}
                          onClick={() => ( disabled ? {} : setEnabledField( 'description' ) )}
                        >
                          <label className="font-weight-bold m-0 form-control-label">
                            <Intl id="description" />
                          </label>
                          <p>{data.description}</p>
                        </div>
                      )}
                    </Col>
                  </Row>

                  {/* {!!customFields && !!customFields.length && (
                    <Row>
                      {_.map( customFields, ( item ) => (
                        <Col md={6} key={item.id}>
                          {enabledField === item.name ? (
                            <>
                              <CustomField
                                field={item}
                                labelClass="font-weight-bold"
                              />

                              <TaskTemporalFormBtns
                                onSave={() => onSubmitFn(
                                  form.getState().values,
                                  item.name,
                                  item.name,
                                  () => setEnabledField( null ),
                                )}
                                onClickCancel={() => setEnabledField( null )}
                              />
                            </>
                          ) : (
                            <div
                              className={classnames( {
                                'cursor-pointer': !disabled,
                              } )}
                              onClick={() => ( disabled ? {} : setEnabledField( item.name ) )}
                            >
                              <label className="font-weight-bold m-0 form-control-label">
                                {item.displayName}
                              </label>
                              <p>{data[item.name] || '-'}</p>
                            </div>
                          )}
                        </Col>
                      ) )}
                    </Row>
                  )} */}

                  <p className="font-weight-bold mb-1 form-control-label text-md">
                    <Intl id="attachments" />
                  </p>
                  <ImagesDropzoneGeneric
                    form={form}
                    canDelete={!isClosed}
                    canAdd={!isClosed}
                    onAddImage={onAddImage}
                    onDeleteImage={onDeleteImage}
                  />

                  <TaskComments
                    data={data.notes || []}
                    taskClosed={isClosed}
                    onSubmit={() => onSubmitComment( form.getState().values, () => form.change( 'newComment', null ) )}
                    onDelete={onDeleteComment}
                  />
                </Col>

                <Col lg={4}>
                  <Row className="mb-3">
                    <Col>
                      <Select
                        field="status"
                        label="status"
                        labelClass="font-weight-bold"
                        isDisabled={isClosed}
                        options={taskStatuses}
                        translateOptions
                        formGroupClass={classnames(
                          'm-0 task-status-select',
                          form.getFieldState( 'status' )
                            ? form.getFieldState( 'status' ).value
                            : '',
                        )}
                        onChange={() => onSubmitFn( form.getState().values, 'status', 'status' )}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col>
                      <Datepicker
                        // field="date"
                        label="scheduledFor"
                        dateFormat="DD/MM/YYYY"
                        timeFormat="HH:mm"
                        utc
                        value={date}
                        labelClass="font-weight-bold"
                        formGroupClass="m-0"
                        startDateField={{
                          value: moment().subtract( '1', 'day' ),
                        }}
                        onChange={( value ) => setDate( value )}
                        onBlur={() => {
                          onSubmitFn( {
                            ...form.getState().values,
                            date,
                          }, 'date', 'date' );
                        }}
                      />
                    </Col>
                  </Row>

                  {!_.isEmpty( data ) && (
                    <>

                      <Row className="mb-3">
                        <Col>
                          <Select
                            field="team"
                            label="assignedToTeam"
                            labelClass="font-weight-bold"
                            formGroupClass="m-0"
                            isSearchable
                            isDisabled={noReassign}
                            options={availableTeams}
                            onChange={setSelectedTeam}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col>
                          <Select
                            field="assignedTo"
                            label="assignedTo"
                            labelClass="font-weight-bold"
                            formGroupClass="m-0"
                            isSearchable
                            isDisabled={noReassign}
                            options={availableEmployees}
                            placeholder="assignTeamFirst"
                            onChange={() => onAssignFn( form.getState().values, selectedTeam )}
                          />
                        </Col>
                      </Row>
                    </>
                  )}

                  <Row className="mb-3">
                    <Col>
                      <Select
                        field="priority"
                        label="priority"
                        labelClass="font-weight-bold"
                        translateOptions
                        isDisabled={isClosed}
                        options={_.map( taskPriorities, ( priority ) => ( {
                          id: priority.id,
                          name: priority.name,
                          icon: (
                            <i
                              className={classnames(
                                priority.iconClass,
                                'text-2xsm',
                              )}
                            />
                          ),
                        } ) )}
                        formGroupClass="m-0"
                        onChange={() => onSubmitFn(
                          form.getState().values,
                          'priority',
                          'priority',
                        )}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col>
                      <p className="font-weight-bold text-sm mb-1 form-control-label">
                        <Intl id="reported" />
                      </p>
                      {!data.registered ? (
                        '-'
                      ) : (
                        <Row>
                          {!!data.registered && (
                            <Col xs="auto" className="pr-0">
                              <UserAvatar
                                avatar={_.get(
                                  data.registered.customer,
                                  'profilePicture',
                                )}
                                initials={getInitials( data.registered.customer )}
                              />
                            </Col>
                          )}
                          <Col className="pl-2">
                            {!!data.registered && (
                              <p className="m-0 text-sm">
                                {getResourceName( data.registered.customer )}
                              </p>
                            )}
                            {!!data.registered && (
                              <p className="m-0 text-sm text-gray-9 lh-100">
                                {moment( data.registered.date ).format(
                                  'DD-MM-YYYY hh:mma',
                                )}
                              </p>
                            )}
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col>
                      <p className="font-weight-bold text-sm mb-1 form-control-label">
                        <Intl id="solved" />
                      </p>
                      <p className="text-sm m-0">-</p>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col>
                      <p className="font-weight-bold text-sm mb-1 form-control-label">
                        <Intl id="closed" />
                      </p>
                      {!data.closed ? (
                        '-'
                      ) : (
                        <Row>
                          {!!data.closed.closedBy && (
                            <Col xs="auto" className="pr-0">
                              <UserAvatar
                                avatar={_.get(
                                  data.closed.closedBy,
                                  'profilePicture',
                                )}
                                initials={getInitials( data.closed.closedBy )}
                              />
                            </Col>
                          )}
                          <Col className="pl-2">
                            {!!data.closed.closedBy && (
                              <p className="m-0 text-sm">
                                {getResourceName( data.closed.closedBy )}
                              </p>
                            )}
                            {!!data.closed.closedAt && (
                              <p className="m-0 text-sm text-gray-9 lh-100">
                                {moment( data.closed.closedAt ).format(
                                  'DD-MM-YYYY hh:mma',
                                )}
                              </p>
                            )}
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <BookingOpenLockerBtns
                        data={data}
                        onOpenDoorModal={openOpenDoorModal}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          }}
        />
        <BookingOpenLockerModal
          data={data}
          doorType={doorTypeToOpen.current}
          lockerId={doorIdToOpen.current}
          modalOpened={openModalOpen}
          onCloseModal={closeOpenDoorModal}
        />
      </>
    );
  },
);
